import Warehouse from "./Warehouse.vue"
import WarehouseCreate from "./Create.vue"
import WarehouseEdit from "./Edit.vue"
import WarehouseShow from "./Show.vue"


export default [
    {
        path: "/warehouse",
        component: Warehouse,
        name: "Warehouse",
        meta: {
            guest: false,
            authenticated: true
        }
    },
    {
        path: "/warehouse/create",
        component: WarehouseCreate,
        name: "WarehouseCreate",
        meta: {
            guest: false,
            authenticated: true
        }
    },
    {
        path: "/warehouse/edit/:id",
        component: WarehouseEdit,
        name: "WarehouseEdit",
        meta: {
            guest: false,
            authenticated: true
        }
    },
    {
        path: "/warehouse/:id",
        component: WarehouseShow,
        name: "WarehouseShow",
        meta: {
            guest: false,
            authenticated: true
        }
    },
]